@import "~@angular/material/prebuilt-themes/indigo-pink.css";
/* * * * * * * * * * * Variables * * * * * * * * * * */
$main-text-police: Roboto, RobotoFallback, "Noto Kufi Arabic", Helvetica, Arial, sans-serif;
$side-nav-color: #023765;
$sub-title-color: #232932;
$header-company-color: #214068;
$title-black-color: #214068;
$primary-color: #00569c;
$side-nav-text-color: #62829f;
$primary-color-light: #95adca;
$secondary-primary-color: black;
$primary-color-red: red;
$primary-color-green: #2ab71b;
$card-green-additional: #224c36;
$third-primary-color: #333;
$primary-light-color: #707070;
$secondary-color-gray: #5d5d67;
$grid-column-header-color: #7e7e7e;
$grid-group-panel-color: #595959;
$secondary-color: #c1c1c1;
$container-background-color: #f7f7f7;
$container-background-light: #ececec;
$card-border: #efefef;
$container-background-white: white;
$grid-search-bar-color: #f6f7f8;
$grid-content-background-color: #e8eaeb;
$grid-line-color: #ffffff;
$grid-group-row: #eeeeee;
$border-line-color: #ddd;
$menu-icon-color: #d6d6d6;
$side-nav-width: 360px;
$application-global-margin: 50px;
$font-size-app-name: 20px;
$font-size-company-name: 22px;
$font-size-user-name: 16px;
$font-size-title-style: 18px;
$font-size-company-name-card: 14px;
$font-size-company-address-card: 12px;
$font-size-treeview-item: 16px;
$font-size-project-title-card: 16px;
$font-size-project-card-items: 10px;
$font-size-project-card-title: 12px;
$button-border-radius: 10px;

:root {
  --primary-color: #{$primary-color};
  --primary-color-light: #{$primary-color-light};
  --primary-light-color: #{$primary-light-color};
  --secondary-color: #{$secondary-color};
  --container-background-color: #{$container-background-color};
  --nav-toolbar-text-color: #{$primary-color};
  --side-nav-background-color: #{$primary-color};
  --app-text-police: #{$main-text-police};
  --container-title-color: #{$primary-color};
  --application-global-margin: #{$application-global-margin};
  --side-nav-width: #{$side-nav-width};
  --side-nav-color: #{$side-nav-color};
  --side-nav-text-color: #{$side-nav-text-color};
  --secondary-color-gray: #{$secondary-color-gray};
  --container-background-white: #{$container-background-white};
  --header-company-color: #{$header-company-color};
  --font-size-app-name: #{$font-size-app-name};
  --font-size-company-name: #{$font-size-company-name};
  --font-size-user-name: #{$font-size-user-name};
  --font-size-title-style: #{$font-size-title-style};
  --font-size-company-name-card: #{$font-size-company-name-card};
  --font-size-company-address-card: #{$font-size-company-address-card};
  --font-size-treeview-item: #{$font-size-treeview-item};
  --font-size-project-title-card: #{$font-size-project-title-card};
  --font-size-project-card-items: #{$font-size-project-card-items};
  --font-size-project-card-title: #{$font-size-project-card-title};
  --grid-column-header-color: #{$grid-column-header-color};
  --grid-search-bar-color: #{$grid-search-bar-color};
  --grid-content-background-color: #{$grid-content-background-color};
  --button-border-radius: #{$button-border-radius};
  --title-black-color: #{$title-black-color};
  --grid-line-color: #{$grid-line-color};
  --container-background-light: #{$container-background-light};
  --border-line-color: #{$border-line-color};
  --third-primary-color: #{$third-primary-color};
  --secondary-primary-color: #{$secondary-primary-color};
  --primary-color-red: #{$primary-color-red};
  --sub-title-color: #{$sub-title-color};
  --card-green-additional: #{$card-green-additional};
  --card-border: #{$card-border};
  --menu-icon-color: #{$menu-icon-color};
  --grid-group-row: #{$grid-group-row};
  --card-radius: 12px;
  --card-padding: 15px;
  --app-name-text-size: 32px;
  --nav-toolbar-height: 100px;
  --nav-toolbar-height-items-container: 55px;
  --layout-title-width: 800px
}

@mixin style-bloc {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  display: block;
}

@mixin ellipsis {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  display: block;
}


/* * * * * * * * * * * App Style * * * * * * * * * * */
html,
body {
  margin: 0px;
  min-height: 100%;
  height: 100%;
}

.dx-fa-button-icon {
  background-color: var(--primary-color) !important;
}

.content-block {
  height: calc(100vh - 220px);

  .screen-x-small & {
    margin-left: 20px;
    margin-right: 20px;
  }

  .dx-card {
    box-shadow: 0px 0px 0px !important;
  }
}

.responsive-paddings {
  height: 100%;
  padding: 10px;
}

.container {
  background-color: var(--container-background-light);
}

/* * * * * * * * * * * Theme * * * * * * * * * * */
/* Widget */
.dx-widget {
  font-family: var(--app-text-police);
}

/* Card */
.dx-card {
  background-color: var(--third-color);
}

.card-paddings {
  height: 100%;
  padding: 30px 30px 30px 30px;
  margin: 10px;
}

.card-container {
  border-radius: var(--card-radius) !important;
  border: none !important;
  padding: var(--card-padding) !important;
  text-overflow: 'ellipsis';
}

.container-card {
  background-color: var(--container-background-color);
  border-radius: var(--button-border-radius);
  padding: var(--card-padding) !important;
  text-overflow: 'ellipsis';
}

.card-radius {
  border-radius: var(--card-radius) !important;
}

/* TreeView */
.dx-checkbox+.dx-treeview-node-container,
.dx-treeview-node-container:first-child {
  margin: 0;
  display: block;
}

.dx-treeview-node-container {
  list-style-position: inside;
  padding: 0;
  margin: 0;
  display: none;
  overflow: hidden;
}

.dx-treeview-node {
  list-style-type: none;
  position: relative;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -webkit-touch-callout: none;
  -webkit-user-drag: none;
}

.dx-treeview-item .dx-treeview-item-content span {
  vertical-align: middle;
}

.dx-treeview-item {
  display: block;
  cursor: pointer;
  padding: 13px 8px;
  min-height: 48px;
  line-height: 22px;
}

.dx-treeview-item .dx-treeview-item-content>.dx-icon {
  display: inline-block;
  vertical-align: middle;
  margin-right: 5px;
  width: 24px;
  height: 24px;
  background-position: 0 0;
  background-size: 24px 24px;
  padding: 0;
  font-size: 24px;
  text-align: center;
  line-height: 24px;
}

.dx-treeview-item.dx-state-hover {
  background-color: rgba(0, 0, 0, 0.336);
  color: rgba(0, 0, 0, 0.87);
}

/* Toolbar */
.dx-toolbar-items-container {
  position: relative;
  overflow: hidden;
  width: 100%;
  height: 100%;
}

.dx-toolbar-item {
  display: table-cell;
  padding: 0 5px;
  vertical-align: middle;
  -webkit-box-sizing: content-box;
  box-sizing: content-box;
}

.dx-toolbar-after,
.dx-toolbar-before,
.dx-toolbar-center {
  top: 0;
  display: table;
  height: 100%;
}

.dx-toolbar-after,
.dx-toolbar-before {
  position: absolute;
}

::ng-deep .dx-toolbar.header-toolbar .dx-toolbar-items-container .dx-toolbar-after {
  padding: 0 40px;

  :host-context(.screen-x-small) & {
    padding: 0 20px;
  }
}

.dx-toolbar-before {
  left: 0;
}

.dx-toolbar-after {
  right: 0;
}

.dx-toolbar .dx-toolbar-items-container {
  overflow: visible;
}

.dx-toolbar .dx-toolbar-item:last-child {
  padding: 0;
}

.dx-toolbar .dx-toolbar-label {
  font-size: 20px;
  font-weight: 500;
}

/* Drawer Overlay (Open/Close) */
.dx-overlay-content {
  position: absolute;
  pointer-events: auto;
  z-index: 1000;
  outline: 0;
  overflow: hidden;
}

/* Drawer */
.dx-drawer {
  height: 100%;
  width: 100%;
}

.dx-drawer-wrapper {
  position: relative;
  overflow: hidden;
  height: 100%;
}

.dx-drawer-shrink .dx-drawer-panel-content {
  position: relative;
  overflow: hidden;
  -webkit-box-flex: 0;
  -webkit-flex: 0 0 auto;
  -ms-flex: 0 0 auto;
  flex: 0 0 auto;
  height: 100%;
}

.dx-drawer-shrink .dx-drawer-wrapper {
  -webkit-box-flex: 1;
  -webkit-flex: 1 0 auto;
  -ms-flex: 1 0 auto;
  flex: 1 0 auto;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
}

.dx-drawer-shrink .dx-drawer-content {
  position: relative;
  -webkit-box-flex: 1;
  -webkit-flex: 1 1 auto;
  -ms-flex: 1 1 auto;
  flex: 1 1 auto;
}

::ng-deep .dx-drawer-content {
  display: flex !important;
}

.dx-drawer-shrink.dx-drawer-bottom .dx-drawer-wrapper,
.dx-drawer-shrink.dx-drawer-top .dx-drawer-wrapper {
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
}

.dx-drawer-overlap .dx-drawer-panel-content {
  overflow: visible;
}

.dx-drawer-overlap .dx-drawer-panel-content.dx-overlay-wrapper {
  right: auto;
  left: auto;
}

.dx-drawer-overlap.dx-drawer-left .dx-drawer-wrapper,
.dx-drawer-overlap.dx-drawer-right .dx-drawer-wrapper {
  overflow-y: visible;
}

.dx-drawer-overlap.dx-drawer-bottom .dx-drawer-wrapper,
.dx-drawer-overlap.dx-drawer-top .dx-drawer-wrapper {
  overflow-x: visible;
}

.dx-drawer-overlap.dx-drawer-right.dx-drawer-expand>.dx-drawer-wrapper>.dx-overlay>.dx-overlay-wrapper>.dx-overlay-content,
.dx-drawer-overlap.dx-drawer-right.dx-drawer-slide>.dx-drawer-wrapper>.dx-overlay>.dx-overlay-wrapper>.dx-overlay-content {
  right: 0;
}

.dx-button-has-text {
  min-width: 64px;
}

.dx-button-has-text .dx-button-content {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  padding: 6px 16px;
}

.dx-button-mode-text .dx-icon {
  color: rgba(0, 0, 0, 0.87);
}

.dx-button-mode-text.dx-state-focused,
.dx-button-mode-text.dx-state-hover {
  background-color: rgba(0, 0, 0, 0.08);
  -webkit-box-shadow: none;
  box-shadow: none;
}

.dx-button-mode-text.dx-state-active {
  background-color: rgba(0, 0, 0, 0.3);
  -webkit-box-shadow: none;
  box-shadow: none;
}

.dx-button-mode-text.dx-state-disabled {
  background: 0 0;
  -webkit-box-shadow: none;
  box-shadow: none;
}

.dx-button-mode-text.dx-state-disabled .dx-icon {
  opacity: 0.6;
}

/* ContextMenu */
.dx-context-menu.dx-overlay-content {
  overflow: inherit;
  position: absolute;
}

.dx-context-menu .dx-menu-item .dx-menu-item-content {
  padding: 5px 10px;
  display: flex;
}

.dx-context-menu .dx-menu-item.dx-menu-item-has-text.dx-menu-item-has-icon .dx-icon {
  margin-right: 15px;
}

.dx-context-menu .dx-menu-items-container {
  padding: 8px 0;
  margin: 0px;
}

.dx-context-menu .dx-submenu {
  background-color: var(--grid-line-color);
  border-radius: 4px;
  -webkit-box-shadow: 0 2px 7px rgba(0, 0, 0, 0.2);
  box-shadow: 0 2px 7px rgba(0, 0, 0, 0.2);
  width: max-content;
}

.dx-menu-base .dx-menu-items-container {
  list-style-type: none;
  cursor: pointer;
}

.side-by-side {
  float: left;
}

.drop-down-style {
  .dx-dropdowneditor-icon::before {
    content: url(assets/images/accordion-open.svg) !important;
    width: 15px;
  }
}

.date-box-style {
  .dx-dropdowneditor-icon::before {
    content: url(assets/images/Termine.svg) !important;
  }
}

.accordionStyle {
  .dx-accordion-item-opened>.dx-accordion-item-title::before {
    display: block !important;
  }

  .dx-accordion-item-title::before {
    display: block !important;
  }
}

.add-button-style {
  .dx-button-has-text .dx-button-content {
    padding: 15px;
    color: var(--container-background-white) !important;
  }

  .dx-button-mode-contained {
    background-color: var(--primary-color) !important;
    border-color: var(--grid-line-color) !important;
    border-radius: 30px;
  }

  .dx-icon.dx-icon-add::before {
    color: var(--container-background-white) !important;
  }

  .dx-icon.dx-icon-refresh::before {
    color: var(--container-background-white) !important;
  }
}

.dx-dropdowneditor-icon::before {
  content: url(/assets/images/accordion-open.svg) !important;
}

.dx-texteditor-buttons-container .dx-widget .dx-button-mode-contained .dx-button-normal .dx-dropdowneditor-button .dx-button-content {
  background-color: transparent !important;
}


.dx-dropdowneditor.dx-state-active .dx-dropdowneditor-icon,
.dx-dropdowneditor.dx-state-hover:not(.dx-custom-button-hovered) .dx-dropdowneditor-icon {
  background-color: transparent !important;
}

.dx-dropdowneditor-button.dx-state-active .dx-dropdowneditor-icon,
.dx-dropdowneditor.dx-dropdowneditor-active .dx-dropdowneditor-icon {
  background-color: transparent !important;
  border-color: transparent;
}

.dx-dropdowneditor-button.dx-state-active .dx-dropdowneditor-icon {
  background-color: transparent !important;
}

.grid-group-by-Style {
  .dx-datagrid-borders>.dx-datagrid-filter-panel {
    margin-top: 10px;
    border-radius: 10px;
    border-left: 1px solid var(--border-line-color);
    border-right: 1px solid var(--border-line-color);
    border-top: 1px solid var(--border-line-color);
    border-bottom: 1px solid var(--border-line-color);
  }

  .dx-datagrid-group-panel .dx-group-panel-item {
    color: var(--primary-light-color) !important;
    font-weight: 700 !important;
  }

  .dx-datagrid-group-panel .dx-group-panel-item {
    color: var(--primary-light-color) !important;
    font-weight: 700 !important;
  }

  .dx-datagrid-headers {
    border: 0px !important;
    margin-bottom: 15px;

    .dx-datagrid-table .dx-row>td {
      border: 0px !important;
      padding: 5px 10px 5px 10px !important;
    }

    .dx-datagrid-content {
      border-radius: 10px;
    }

    .dx-column-lines {
      background-color: var(--grid-search-bar-color);
      align-content: center;
      height: 20px;
    }
  }

  .dx-button-mode-contained .dx-icon {
    color: var(--primary-light-color) !important;
  }

  .dx-icon-rapport::before {
    vertical-align: center center !important;
    padding-right: 0px;
    content: url(assets/images/rapport.svg);
  }

  .dx-icon-edit::before {
    vertical-align: center center !important;
    padding-right: 0px;

    content: url(assets/images/Edit.svg);
  }

  .dx-icon-revert::before {
    content: url(assets/images/revert.svg) !important;
    vertical-align: -webkit-baseline-middle;
  }

  .dx-datebox-calendar .dx-dropdowneditor-icon::before {
    content: url(assets/images/Termine.svg) !important;
  }

  .dx-datagrid .dx-column-indicators .dx-header-filter.dx-header-filter:after {
    content: url(assets/images/Filter.svg) !important;
    top: 6px !important;
    right: 4px !important;
    left: -7px !important;
  }

  .dx-datagrid .dx-header-filter::before {
    content: url(assets/images/Filter.svg) !important;
    fill: var(--primary-light-color) !important;

  }

  .dx-icon-edit::before {
    content: url(assets/images/Edit_.svg);
  }

  .dx-dropdowneditor-icon::before {
    content: url(assets/images/accordion-open.svg) !important;
    fill: var(--primary-light-color) !important;
    width: 15px;
  }

  .dx-icon-export::before {
    content: url(assets/images/Export.svg) !important;
  }

  .dx-icon-column-chooser::before {
    content: url(assets/images/FilterEdit.svg) !important;
  }

  .dx-icon-pdffile::before {
    content: url(assets/images/pdf_.svg) !important;
  }

  .dx-texteditor.dx-editor-outlined {
    border: none !important;
  }

  .dx-datagrid-focus-overlay {
    border: 0px;
  }

  .dx-empty {
    background-color: var(--grid-content-background-color);
  }

  .dx-checkbox-indeterminate .dx-checkbox-icon::before {
    background-color: var(--secondary-color);
  }

  .dx-checkbox .dx-state-hover .dx-checkbox-icon {
    border: 0px !important;
  }

  .dx-checkbox .dx-state-hover .dx-checkbox-icon {
    border: 0px !important;
  }

  .dx-checkbox-checked .dx-checkbox-icon {
    color: var(--secondary-color);

  }

  .dx-button-content {
    background-color: var(--grid-search-bar-color);
    border-radius: 10px !important;

    .dx-dropdowneditor-icon {
      background-color: var(--grid-line-color) !important;
    }
  }

  .dx-datagrid .dx-row-lines>td {
    border-bottom: 1px solid var(--grid-line-color);
  }

  .dx-datagrid .dx-column-lines>td {
    border-right: 1px solid var(--grid-line-color);
  }

  .dx-datagrid .dx-row>td {
    padding: 15px 10px 15px 10px !important;
  }

  .dx-texteditor-input {
    background-color: var(--grid-search-bar-color);
    border-radius: 10px;
  }

  .dx-button-mode-contained {
    border: none !important;
  }

  .dx-datagrid-rowsview {
    border-radius: 10px;
    border: 0px !important;
  }

  .dx-column-lines {
    background-color: var(--grid-content-background-color);
    align-content: center;
    height: 50px;
  }

  .grid-text-style {
    border-bottom: 1px solid var(--border-line-color);
    justify-content: center;
    vertical-align: middle !important;
  }

  .td-action {
    display: flex;
    justify-content: space-evenly;
  }

  .dx-group-row {
    background-color: var(--grid-group-row) !important;
    color: var(--grid-group-panel-color) !important;
  }

  .dx-datagrid-group-opened {
    color: var(--grid-group-panel-color) !important;
  }

  .dx-datagrid-group-closed {
    color: var(--grid-group-panel-color) !important;
  }

  .dx-datagrid-text-content {
    color: var(--grid-column-header-color);
  }

  .dx-datagrid .dx-datagrid-header-panel .dx-group-panel-item,
  .dx-datagrid .dx-datagrid-header-panel .dx-header-filter {
    border-radius: 4px;
  }

  .dx-datagrid-borders>.dx-datagrid-headers,
  .dx-datagrid-borders>.dx-datagrid-pager {
    border-radius: 4px 4px 0px 0px;
    margin-bottom: 15px;
  }

  .dx-datagrid-rowsview .dx-row {
    color: var(--grid-group-panel-color);
  }

  .dx-column-indicators {
    float: right !important;
  }

  .dx-datagrid-group-panel .dx-group-panel-message {
    display: none !important;
  }

  .dx-datagrid-search-panel {
    margin-left: 0px !important;
  }

  .dx-command-edit-with-icons {
    padding-left: 13px !important;
  }

  .dx-searchbox .dx-icon-search::before {
    content: url(assets/images/Search.svg) !important;
    display: block !important;
  }

  .dx-datagrid-group-opened::before {
    content: url(assets/images/accordion-open.svg) !important;
    fill: var(--primary-color) !important;
    width: 12px !important;
  }

  .dx-datagrid-group-closed::before {
    content: url(assets/images/ArrowRight.svg) !important;
    fill: var(--primary-color) !important;
  }

  .dx-datagrid-filter-panel-text {
    font-family: var(--app-font) !important;
    font-size: 12px;
    color: var(--primary-light-color) !important;
  }

  .dx-icon-filter {
    color: var(--primary-light-color) !important;
  }

  .dx-icon-column-chooser::before {
    color: var(--primary-light-color) !important;
  }

  .dx-icon-filter::before {
    color: var(--primary-color) !important;
  }

  .dx-sort {
    padding-right: 15px;
  }

  .dx-info {
    font-weight: 700;
  }

  .grid-action {
    width: 18px;
    height: 17.5px;
    cursor: pointer;
  }
}

.dx-datagrid-export-menu .dx-dropdownbutton-content .dx-list-item {
  color: var(--primary-color) !important;
}

.dx-icon-xlsxfile::before {
  content: url(assets/images/pdf_.svg) !important;
  color: var(--primary-color) !important;
}

.dx-icon-exportselected::before {
  color: var(--primary-color) !important;
}

.dx-icon-context-menu-sort-asc::before {
  color: var(--primary-color) !important;
}

.dx-icon-context-menu-sort-desc::before {
  color: var(--primary-color) !important;
}

.dx-datagrid-column-chooser .dx-popup-title {
  margin-bottom: 5px !important;
}

.dx-accordion-item-title {
  @include ellipsis;
  color: var(--title-black-color) !important;
  font-weight: 600;
  font-size: 18px;
}

.custome-export-style {
  .dx-icon-xlsxfile::before {
    color: var(--third-primary-color) !important;
  }
}

.tileNavArrow {
  float: left;
  margin-top: 8px;
  margin-left: 10px;
  margin-right: 10px;
}

.contentClass {
  padding-left: 24px;
  padding-bottom: 40px;
  padding-right: 24px;
  height: 40%;
  background-color: var(--container-background-color);
  border-top-style: solid;
  border-top-color: var(--border-line-color);
}

.tileNav {
  float: left;
  margin-left: 10px;
  margin-top: 5px;
  margin-bottom: 0px;
  font-size: large;
  font-weight: bold;
}

.tree-list-style {
  .dx-treelist-borders>.dx-treelist-filter-panel {
    margin-top: 10px;
    border-radius: 10px;
    border-left: 1px solid #ddd;
    border-right: 1px solid #ddd;
    border-top: 1px solid #ddd;
    border-bottom: 1px solid #ddd;
  }

  .dx-treelist-group-panel .dx-group-panel-item {
    color: var(--primary-light-color) !important;
    font-weight: 700 !important;
  }

  .dx-treelist-group-panel .dx-group-panel-item {
    color: var(--primary-light-color) !important;
    font-weight: 700 !important;
  }

  .dx-treelist-headers {
    border: 0px !important;

    .dx-treelist-table .dx-row>td {
      border: 0px !important;
      padding: 5px 10px 5px 10px !important;
    }

    .dx-treelist-content {
      border-radius: 10px;
    }

    .dx-column-lines {
      background-color: var(--grid-search-bar-color);
      align-content: center;
      height: 20px;
    }
  }

  .dx-button-mode-contained .dx-icon {
    color: var(--primary-light-color) !important;
  }

  .dx-icon-edit::before {
    vertical-align: center center !important;
    padding-right: 0px;

    content: url(assets/images/Edit.svg);
  }

  .dx-icon-revert::before {
    content: url(assets/images/revert.svg) !important;
    vertical-align: -webkit-baseline-middle;
  }

  .dx-treelist-focus-overlay {
    border: 0px;
  }

  .dx-empty {
    background-color: var(--grid-content-background-color);
  }

  .dx-checkbox-indeterminate .dx-checkbox-icon::before {
    background-color: var(--secondary-color);
  }

  .dx-checkbox .dx-state-hover .dx-checkbox-icon {
    border: 0px !important;
  }

  .dx-checkbox .dx-state-hover .dx-checkbox-icon {
    border: 0px !important;
  }

  .dx-checkbox-checked .dx-checkbox-icon {
    color: var(--secondary-color);
  }

  .dx-treelist .dx-row-lines>td {
    border-bottom: 2px solid var(--grid-line-color);
  }

  .dx-treelist .dx-column-lines>td {
    border-right: 2px solid var(--grid-line-color) !important;
  }

  .dx-treelist .dx-row>td {
    padding: 15px 10px 15px 10px !important;
  }

  .dx-texteditor-input {
    background-color: var(--grid-search-bar-color);
  }

  .dx-treelist-rowsview {
    border-radius: 10px;
    border: 0px !important;
  }

  .dx-column-lines {
    background-color: var(--grid-content-background-color);
    align-content: center;
    height: 50px;
  }

  .grid-text-style {
    border-bottom: 1px solid #ddd;
    justify-content: center;
    vertical-align: middle !important;
  }

  .td-action {
    display: flex;
    justify-content: space-evenly;
  }

  .dx-group-row {
    background-color: var(--grid-group-row) !important;
    color: var(--grid-group-panel-color) !important;
  }

  .dx-treelist-group-opened {
    color: var(--grid-group-panel-color) !important;
  }

  .dx-treelist-group-closed {
    color: var(--grid-group-panel-color) !important;
  }

  .dx-treelist-text-content {
    color: var(--grid-column-header-color);
    font-weight: 400 !important;
  }

  .dx-treelist .dx-treelist-header-panel .dx-group-panel-item,
  .dx-treelist .dx-treelist-header-panel .dx-header-filter {
    border-radius: 4px;
  }

  .dx-treelist-borders>.dx-treelist-headers,
  .dx-treelist-borders>.dx-treelist-pager {
    border-radius: 4px 4px 0px 0px;
    margin-bottom: 15px;
  }

  .dx-treelist-rowsview .dx-row {
    color: var(--grid-group-panel-color);
  }

  .dx-column-indicators {
    float: right !important;
  }

  .dx-treelist-group-panel .dx-group-panel-message {
    display: none !important;
  }

  .dx-treelist-search-panel {
    margin-left: 0px !important;
  }

  .dx-command-edit-with-icons {
    padding-left: 13px !important;
  }

  .dx-searchbox .dx-icon-search::before {
    content: url(assets/images/Search.svg) !important;
    display: block !important;
  }

  .dx-gantt-i.dx-gantt-i-toggle-resources {
    background-image: url(assets/images/Datenbank.svg);
  }

  .dx-gantt-i.dx-gantt-i-zoom-in {
    background-image: url(assets/images/Details.svg);
  }

  .dx-treelist-filter-panel-text {
    font-family: var(--app-font) !important;
    font-size: 12px;
    color: var(--primary-light-color) !important;
  }

  .dx-icon-filter {
    color: var(--primary-light-color) !important;
  }

  .dx-icon-column-chooser::before {
    color: var(--primary-light-color) !important;
  }

  .dx-icon-filter::before {
    color: var(--primary-light-color) !important;
  }

  .dx-sort {
    padding-right: 15px;
  }

  .dx-info {
    font-weight: 700;
  }

  .grid-action {
    width: 18px;
    height: 17.5px;
    cursor: pointer;
  }

  .dx-treelist-content .dx-treelist-table .dx-row>td {
    vertical-align: middle;
  }

  .dx-treelist-headers .dx-treelist-table .dx-row>td {
    font-weight: 700;
    text-align: left !important;
  }

  .dx-column-indicators {
    float: right !important;
  }

  .dx-header-row>td>.dx-treelist-text-content {
    white-space: normal !important;
  }

  .dx-treelist-container .dx-header-filter::before {
    content: "" !important;
  }

  .dx-treelist-headers {
    color: var(--third-primary-color);
  }

  .dx-header-filter-empty::after {
    top: -6px !important;
    right: 4px !important;
    left: -7px !important;
    content: url(./assets/images/Filter.svg) !important;
  }

  .dx-icon-edit::before {
    content: url(assets/images/Edit.svg);
  }

  .dx-icon-trash::before {
    content: url(assets/images/Delete.svg);
  }

  .dx-icon-column-chooser::before {
    content: url(assets/images/columnChooser.svg) !important;
  }

  .dx-icon-export-to::before {
    content: url(assets/images/exportTo.svg) !important;
  }

  .dx-datebox-calendar .dx-dropdowneditor-icon::before {
    content: url(assets/images/calendar.svg) !important;
  }

  .dx-dropdowneditor-icon::before {
    content: url(assets/images/ddarrow.svg) !important;
  }

  .dx-icon-save::before {
    content: url(assets/images/save.svg) !important;
  }

  .dx-icon-add::before {
    color: var(--primary-color) !important;
  }

  .dx-icon-revert::before {
    content: url(assets/images/revert.svg) !important;
  }
}


.favorite-inactive {
  content: url(assets/images/favorite-inactive.svg) !important;
  width: 18px !important;
}

.favorite-active {
  content: url(assets/images/favorite-active.svg) !important;
  width: 18px !important;
}

.delete {
  content: url(assets/images/Delete.svg) !important;
  vertical-align: center !important;

  width: 18px !important;
}

.title-style {
  margin-left: 5px;
  margin-top: 18px;
  font-weight: 700;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  color: var(--header-company-color);
  width: var(--layout-title-width);
  line-height: 30px;
  letter-spacing: .5px;
  font-size: 20px;
}

.titleDivClass {
  margin: 0px;
  background-color: var(--container-title-color);
  padding: 10px;
}

.ellipsis {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.dx-popup-title {
  background-color: var(--primary-color) !important;
  color: var(--container-background-white) !important;
}

.popup-card-paddings {
  box-shadow: 0 1px 3px #0000001f, 0 1px 2px #0000003d;
  height: 100%;
  padding: 30px 30px 30px 30px;
}

::ng-deep .dx-popup-normal .dx-toolbar.dx-popup-title {
  height: 34px !important;
  display: flex;
  align-items: center;
}

.dx-item-content.dx-toolbar-item-content {
  font-size: 14px;
}

.dx-icon-close {
  color: var(--container-background-white) !important;
}

.windowCreateBackground {
  background-color: var(--primary-color) !important;

  span {
    color: var(--container-background-white);
  }
}

.content-style-class {
  padding: var(--application-global-margin);
  padding-top: 23px;
  height: 100%;
  background-color: var(--container-background-color);
}

::ng-deep .dx-icon-customColumnChooserColumn {
  background-image: url(assets/images/User.svg) !important;
  background-repeat: no-repeat;
  background-position: 0px 0px;
}

::-webkit-scrollbar {
  -webkit-appearance: none;
  width: 3px;
  height: 2px;
}

::-webkit-scrollbar-thumb {
  border-radius: 5px;
  background: gainsboro !important;
  -webkit-box-shadow: 0 0 1px rgba(255, 255, 255, 0.5);
  -moz-box-shadow: 0 0 1px rgba(255, 255, 255, 0.5);
  box-shadow: 0 0 1px rgba(255, 255, 255, 0.5);
  cursor: pointer;
}

::-webkit-scrollbar-thumb:hover {
  background: gainsboro !important;
}

::-webkit-scrollbar-thumb:window-inactive {
  background: rgba(0, 0, 0, 0.05);
}

/* * * * * Document Library Style * * * * */
.iconsStyle {
  .dx-icon.dx-link-icon {
    content: url(assets/images/download.svg) !important;
  }
}

.dx-loadindicator-segment {
  background: var(--primary-color) !important;
}

.dx-loadpanel-message {
  color: var(--primary-color) !important;
}

/* * * * * Form Buttons Style * * * * */
.form-buttons-container {
  display: flex !important;
  justify-content: flex-end !important;

  .dx-box-flex {
    flex-direction: row !important;
    align-items: flex-end !important;
    align-items: flex-end !important;
    gap: 10px;
  }

  .dx-layout-manager .dx-field-item:not(.dx-first-row) {
    padding-bottom: 1px;
  }
}

.dx-icon-textdocument::before {
  color: var(--primary-color) !important;
}

.submit-button {
  .dx-button-content {
    background-color: var(--primary-color) !important;
    max-height: 40px;
    border-color: var(--container-background-white) !important;
    border-radius: var(--button-border-radius) !important;
    color: var(--container-background-white);
  }

  .dx-button-mode-contained {
    border-color: var(--container-background-white) !important;
  }
}

.cancel-button {
  .dx-button-content {
    max-height: 40px;
    border-color: var(--grid-search-bar-color) !important;
    border-radius: var(--button-border-radius) !important;
  }
}


.dx-tag-content {
  position: relative;
  display: inline-block;
  text-align: center;
  cursor: pointer;
  margin: 4px 0 0 4px;
  padding: 3px 25px 4px 6px;
  min-width: 40px;
  background-color: var(--border-line-color);
  border-radius: 2px;
  color: var(--third-primary-color);
  border-radius: 10px !important;
}

/* * * * * * * * * * * * * * * Context menu * * * * * * * * * * * * * * */
#context-menu-icon {
  height: 15px;
  width: 15px;
}

.dx-context-menu .dx-submenu {
  border-radius: 10px !important;
  border-bottom: 1px solid #ccc;
}

.dx-context-menu .dx-menu-no-icons>.dx-menu-item-wrapper:last-child>.dx-menu-item>.dx-menu-item-content .dx-menu-item-text {
  padding-left: 5px;
  border-bottom: 1px solid #ffffff;
  width: 100%;
  padding-bottom: 10px;
}

.dx-menu-base .dx-menu-item-content .dx-menu-item-text {
  width: 100% !important;
}

.dx-context-menu .dx-menu-no-icons>.dx-menu-item-wrapper>.dx-menu-item>.dx-menu-item-content .dx-menu-item-text {
  padding-left: 5px;
  border-bottom: 1px solid #ccc;
  padding-bottom: 10px;
}

last-child .dx-context-menu .dx-menu-items-container {
  padding: 5px;
}

.dx-menu-base .dx-menu-item .dx-menu-item-content {
  padding-top: 11px;
}